import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = () => {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "generic/simon-davies-desktop-with-sheep@2x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)

  return <Img className="w-screen h-screen max-w-full relative z-0" fluid={data.file.childImageSharp.fluid} />
}

export default Image
