import React from "react"
import Image from "../components/image"

import '../css/main.css'

export default ({ children }) => (
  <div className="w-screen max-w-full h-screen max-h-full font-sans relative">
      <div><Image /></div>
    {children}
  </div>
)